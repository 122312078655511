import React from "react";
import Link from "gatsby-link";

import Layout from "../components/Layout";

export default function WasWirTun() {
  return (
    <Layout pageTitle={`Was wir tun`}>
      <div className="was-wir-tun">
        <h2 className="site-content_inner">Was wir tun</h2>
        <p className="site-content_inner">
          Dem Förderverein kommt eine große Be&shy;deutung zu, denn die
          finan&shy;ziel&shy;le Förde&shy;rung er&shy;mög&shy;licht es, eine
          moderne, zukunfts&shy;orien&shy;tierte und gut
          aus&shy;ge&shy;stat&shy;tete Lern&shy;um&shy;ge&shy;bung zu schaffen.
          Bei&shy;spiel&shy;haft seien hier die folgen&shy;den Be&shy;reiche
          ge&shy;nannt:
        </p>
        <div className="stuff1">
          <ul>
            <li className="li2">
              Anschaffung von{" "}
              <strong>
                Unterrichts&shy;materialien, Computern und Beamern, Sport- und
                Spiel&shy;geräten
              </strong>
            </li>
            <li className="li3">
              Unterstützung der <strong>Außen&shy;dar&shy;stel&shy;lung</strong>{" "}
              unserer Schule
            </li>
            <li className="li4">
              Unterstützung von einzel&shy;nen Schüler*in&shy;nen durch{" "}
              <strong>Zu&shy;schüsse zu Klassen&shy;fahrten</strong>
            </li>
            <li className="li5">
              Förderung{" "}
              <strong>schulischer Ver&shy;an&shy;staltun&shy;gen</strong> wie
              z.B. das jähr&shy;liche Sommer&shy;fest, die 175-Jahr-Feier 2012
              oder das 25-jährige Jubi&shy;läum als UNESCO-Projekt&shy;schule im
              Schul&shy;jahr 2017/18
            </li>
            <li className="li6">
              Verschönerung der Schule durch{" "}
              <strong>Re&shy;no&shy;vierungs&shy;ar&shy;beiten</strong>
            </li>
          </ul>
        </div>
        <div className="clear" />
        <p className="site-content_inner">
          Aktuell plant der Förder&shy;verein die Unter&shy;stützung
          weite&shy;rer Projekte:
        </p>
        <div className="stuff2">
          <div className="align-center">
            <ul>
              <li className="li7">
                Kontinuierliche Anschaf&shy;fungen zur Sicher&shy;stellung einer{" "}
                <strong>modernen IT-Aus&shy;stattung</strong>
              </li>
              <li className="li8">
                An&shy;schaf&shy;fung von{" "}
                <strong>Präsentations&shy;wänden und -schränken</strong> zum
                Aus&shy;stellen der Ar&shy;beiten der Schüler*innen
              </li>
              <li className="li9">
                Unterstützung von <strong>Theater&shy;auf&shy;führungen</strong>
              </li>
              <li className="li10">
                <strong>
                  Verschönerung von Schul&shy;hof und Dach&shy;garten
                </strong>
                ; Schaffung von Sitz&shy;gelegen&shy;heiten
              </li>
              <li className="li11">
                Förderung und{" "}
                <strong>
                  Stärkung des Zusam&shy;men&shy;gehörig&shy;keits&shy;gefühls
                </strong>{" "}
                der Schul-Gemein&shy;schaft
              </li>
              <li className="li12">
                und ganz besonders auch weiter&shy;hin die{" "}
                <strong>Unter&shy;stützung</strong> einzel&shy;ner Schüler*innen
              </li>
            </ul>
          </div>
        </div>
        <p className="site-content_inner">
          Sie sehen, es gibt viel zu tun. Daher sind wir auf{" "}
          <Link to="ihre-unterstuetzung">Ihre Unterstützung</Link> angewiesen!
        </p>
      </div>
    </Layout>
  );
}
